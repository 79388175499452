@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
}

html {
	font-family: "Inter var", "Inter", "system-ui", sans-serif;
	font-feature-settings: "cv02", "cv03", "cv04", "cv11";
	-webkit-font-smoothing: antialiased;
	scroll-behavior: smooth;
	overflow-x: hidden;
}

body {
	margin: 0;
}

.slide-in-left-enter {
	transform: translateX(-100%);
}

.slide-in-left-enter-active {
	transform: translateX(0);
	opacity: 1;
	transition: all 200ms;
}

.slide-in-left-exit {
	transform: translateX(0%);
	opacity: 1;
}

.slide-in-left-exit-active {
	transform: translateX(-100%);
	opacity: 0;
	transition: all 200ms;
}

.center {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Clerk Authentication */

.mt-profile .cl-card > div:nth-child(3) {
	display: none;
}

.mt-signin .cl-card > div:nth-child(4) {
	display: none;
}

.mt-signin .cl-card > div:nth-child(5) {
	display: none;
}

.mt-signup .cl-card > div:nth-child(4) {
	display: none;
}

/* Toastify */
.Toastify__toast {
	border-radius: 11px !important;
	border: 1px solid #e5e7eb;
	font-size: 15px;
	box-shadow:
		0 10px 15px -3px rgb(0 0 0 / 0.1),
		0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
}

.Toastify__toast-body {
	color: black;
	font-family: system-ui;
	font-weight: 450;
	padding: 5px 15px !important;
}

.Toastify__toast-icon {
	height: 25px !important;
	width: 25px !important;
}

.Toastify__close-button {
	opacity: 0.4;
	padding: 6px !important;
}

/* Default transition for all hoverable elements */
.hover\:scale-105 {
	transition: transform 0.1s ease-in-out;
}

/* Phone number field */
.PhoneInputInput {
	border: none;
}

.PhoneInputInput:focus,
[type="text"]:focus {
	outline: 0px !important;
	outline-offset: 2px;
	--tw-ring-inset: 0;
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: transparent;
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
		var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0
		calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: none;
	border-color: transparent !important;
}

.PhoneInputCountryIconUnicode {
	font-size: 18px;
}

/* Tooltip */

body .react-tooltip {
	border-radius: 10px;
	background-color: "#101828";
	font-family:
		"Inter var",
		system-ui,
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		"Open Sans",
		"Helvetica Neue",
		sans-serif;
	font-style: normal;
	font-weight: 500;
	/* font-size: 12px; */
	box-shadow:
		0px 4px 6px -2px rgba(16, 24, 40, 0.03),
		0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

/* Gradient Text */
#gradientText {
	background-color: #f03d59;
	background-image: linear-gradient(45deg, #f03d59, #7634de);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}

/* Dopt styling (onboarding)*/
:root {
	--dopt-colors-primary: #7f56d9 !important;
}

/* Time range picker */
.ant-picker-ok button {
	background-color: #7f56d9;
}

.ant-picker-ok button:hover {
	background-color: #6a42c1;
}

.ant-picker-outlined:focus-within {
	border: 2px solid #7f56d9 !important;
	box-shadow: none;
}

.ant-picker .ant-picker-input > input {
	font-size: 0.875rem;
}

.ant-picker {
	padding: 6px 11px !important;
}

/* Date picker */
.ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn {
	display: none;
}

/* Tags input */
.react-tags {
	position: relative;
	padding: 0.25rem 0 0 0.5rem;
	/* border: 1px solid #dce1e5; */
	/* box-shadow: 0 4px 4px 0px #dce1e5, 0 4px 4px 0px #dce1e5 !important; */
	/* border-radius: 6px; */
	background: #ffffff;
	/* shared font styles */
	/* font-size: 1rem; */
	line-height: 1;
	/* clicking anywhere will focus the input */
	cursor: text;
}

.react-tags.is-active {
	border-color: #4f46e5;
}

.react-tags.is-disabled {
	opacity: 0.75;
	background-color: #eaeef2;
	/* Prevent any clicking on the component */
	pointer-events: none;
	cursor: not-allowed;
}

.react-tags.is-invalid {
	border-color: #fd5956;
	box-shadow: 0 0 0 2px rgba(253, 86, 83, 0.25);
}

.react-tags__label {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.react-tags__list {
	/* Do not use display: contents, it's too buggy */
	display: inline;
	padding: 0;
}

.react-tags__list-item {
	display: inline;
	list-style: none;
}

.react-tags__tag {
	margin: 0 0.25rem 0.25rem 0;
	padding: 0.375rem 0.5rem;
	border: 0;
	border-radius: 3px;
	background: #eaeef2;
	/* match the font styles */
	font-size: inherit;
	line-height: inherit;
}

.react-tags__tag:hover {
	color: #ffffff;
	background-color: #4f46e5;
}

.react-tags__tag::after {
	content: "";
	display: inline-block;
	width: 0.65rem;
	height: 0.65rem;
	clip-path: polygon(
		10% 0,
		0 10%,
		40% 50%,
		0 90%,
		10% 100%,
		50% 60%,
		90% 100%,
		100% 90%,
		60% 50%,
		100% 10%,
		90% 0,
		50% 40%
	);
	margin-left: 0.5rem;
	font-size: 0.875rem;
	background-color: #7c7d86;
}

.react-tags__tag:hover::after {
	background-color: #ffffff;
}

.react-tags__combobox {
	display: inline-block;
	/* match tag layout */
	padding: 0.375rem 0.25rem;
	margin-bottom: 0.25rem;
	/* prevents autoresize overflowing the container */
	max-width: 100%;
}

.react-tags__combobox-input {
	/* prevent autoresize overflowing the container */
	max-width: 100%;
	/* remove styles and layout from this element */
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	background: none;
	/* match the font styles */
	font-size: inherit;
	line-height: inherit;
}

.react-tags__combobox-input::placeholder {
	color: #7c7d86;
	opacity: 1;
}

.react-tags__listbox {
	position: absolute;
	z-index: 1;
	top: calc(100% + 5px);
	/* Negate the border width on the container */
	left: -2px;
	right: -2px;
	max-height: 12.5rem;
	overflow-y: auto;
	background: #ffffff;
	border: 1px solid #afb8c1;
	border-radius: 6px;
	box-shadow:
		rgba(0, 0, 0, 0.1) 0 10px 15px -4px,
		rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
}

.react-tags__listbox-option {
	padding: 0.375rem 0.5rem;
}

.react-tags__listbox-option:hover {
	cursor: pointer;
	background: #eaeef2;
}

.react-tags__listbox-option:not([aria-disabled="true"]).is-active {
	background: #4f46e5;
	color: #ffffff;
}

.react-tags__listbox-option[aria-disabled="true"] {
	color: #7c7d86;
	cursor: not-allowed;
	pointer-events: none;
}

.react-tags__listbox-option[aria-selected="true"]::after {
	content: "✓";
	margin-left: 0.5rem;
}

.react-tags__listbox-option[aria-selected="true"]:not(.is-active)::after {
	color: #4f46e5;
}

.react-tags__listbox-option-highlight {
	background-color: #ffdd00;
}

/* Settings sidebar animation */

.slide-in {
	animation: slide-in 0.2s ease-in-out;
}

@keyframes slide-in {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
}

/* Frigade onboarding */
.fr-carousel {
	background-color: transparent !important;
	border: none !important;
}

/* Add this CSS either in a separate file or in your global styles */
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.animate-fade-in {
	animation: fadeIn 0.3s ease-in-out;
}

/* Animations */
.animate-zoomIn {
	animation: zoomIn 0.4s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

.animate-moveInBottom {
	animation: moveInBottom 0.4s cubic-bezier(0.7, 0, 0.3, 1) backwards;
}

@keyframes moveInBottom {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes zoomIn {
	from {
		opacity: 0;
		transform: scale(0.5);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

/* Tag input (emails) */

.ReactTags__tagInputField {
	border: none !important;
	padding: 0 !important;
	margin: 0 !important;
	font-size: 14px;
	width: 100% !important;
}

.tag-wrapper svg {
	fill: #7c7d86 !important;
	height: 10px !important;
}
